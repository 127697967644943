import React, { useState, useEffect } from 'react';
import i18n from '@i18n';
// import firebase from '@firebase-config';
import { Autocomplete, appcenter, store as zerocodeStore, firebaseNewton, dokia } from 'zerocode';
// import { history } from '@redux';
import { useSelector, connect } from 'react-redux';
import { renderExecutionFlowMenuItem, renderTaskMenuItem, sortByUrgency } from '@custom/common';
// import { Typography, TextField } from '@material-ui/core';
// import { Autocomplete } from '@material-ui/lab';

import '@assets/sass/dashboard.scss';
import Chart from 'react-apexcharts';
import Icon from '@custom/Icon';
import WorkflowActionCard, { workflowCollection, companyAreaCollection, checkLicenses } from '../@custom/WorkflowActionCard';

let debounceDonut, debounceAuthenticated;

const Dashboard = ({ auth, executionFlowStore, executionFlowStoreMine, taskStore, companyAreaStore, recurrenceStore }) => {
	const [workflows, setWorkflows] = useState([]);
	const [donutA, setDonutA] = useState({
		chartOptions: {
			labels: [],
		},
		options: {
			labels: [],
		},
		series: [],
	});
	const [hasLicensesMappings, setHasLicensesMappings] = useState(false);
	const [loadingHasLicenses, setLoadingHasLicenses] = useState(false);
	const [maxMappingsLicensesAvailable, setMaxMappingsLicensesAvailable] = useState(false);

	const appcenterStore = zerocodeStore.getState().appcenter;

	useEffect(() => {
		checkNecessaryLicenses();
	}, [appcenterStore.licenses]);

	const checkNecessaryLicenses = () => {
		checkLicenses((result) => {
			setHasLicensesMappings(result.hasEnoughMappingsLicense);
			setMaxMappingsLicensesAvailable(result.mappingsLicensesAvailable);

			setLoadingHasLicenses(true);
		});
	};

	useEffect(() => {
		clearTimeout(debounceAuthenticated);

		debounceAuthenticated = setTimeout(() => {
			workflowCollection
				.where('company', '==', auth.company.uid)
				.where('deleted', '==', false)
				.get()
				.then((snapshot) => {
					setWorkflows(
						snapshot.docs
							.map((d) => d.data())
							.filter((o) => {
								if (!o.division) return false;

								if (auth.user.companyadmin) return true;
								let ca = companyAreaStore.list.find((c) => (c.uid === o.division ? c : false));
								if (!ca) return false;
								if (ca.groups && ca.groups.length) {
									let found = false;
									ca.groups.find((group) => {
										auth.user.groups.find((uGroup) => {
											if (uGroup.uid === group.uid) {
												found = true;
												return uGroup;
											}
										});
										if (found) {
											return found;
										}
									});
									return found;
								}
								return true;
							})
							.sortBy(['divisionObject.name', 'document.title'])
					);
				});
		}, 400);
	}, [auth.isAuthenticated]);

	useEffect(() => {
		clearTimeout(debounceDonut);

		debounceDonut = setTimeout(() => {
			let workflows = {};
			taskStore.list.map((task) => {
				if (workflows[task.workflow] === undefined) {
					workflows[task.workflow] = {
						count: 0,
						label: task.workflowObject.document.title,
					};
				}
				workflows[task.workflow].count++;
			});
			donutA.options.labels = Object.values(workflows).map((o) => o.label);
			//(donutA.options.labels);
			donutA.series = Object.values(workflows).map((o) => o.count);
			setDonutA({
				...donutA,
				chartOptions: {
					labels: [...donutA.options.labels],
				},
				options: {
					labels: [...donutA.options.labels],
				},
			});
		}, 500);
	}, [taskStore.list]);

	//('donutA.options', donutA.options);
	//('donutA.series', donutA.series);

	return (
		<content>
			<div className="workflow-search-container">
				<Autocomplete
					preventCloseOnBlur
					className="workflow-search"
					items={workflows}
					label={i18n("workflow.dashboard.labelSearchProcess")}
					leftIcon='faSearch'
					getComparisonText={(option) => {
						return option.document.title + ' ' + option.divisionObject.name + ' ' + option.document.description || '';
					}}
					renderItem={(workflow) => [
						<WorkflowActionCard
							displayDivision={true}
							auth={auth}
							workflow={workflow}
							companyAreaStore={companyAreaStore}
							recurrenceStore={recurrenceStore}
							hasLicensesMappings={hasLicensesMappings}
							maxMappingsLicensesAvailable={maxMappingsLicensesAvailable}
						/>,
					]}
				/>
			</div>

			<header>
				<h3>
					<Icon icon="faChartPie" />
					Dashboard
				</h3>
			</header>

			<div className="columns graphic-line">
				<div className="column tasks">
					<h3>
						<Icon icon="faTasks" />
						{i18n("workflow.dashboard.titleMyActivities")}
					</h3>
					<div className="items">
						{taskStore.list.sort(sortByUrgency).map(renderTaskMenuItem)}
					</div>
				</div>
				<div className="column">
					{!!donutA.options.labels.length && (
						<Chart
							style={{ height: 300, width: 300 }}
							options={donutA.options}
							series={donutA.series}
							type="donut"
							width={'400px'}
							height={'500px'}
						/>
					)}
				</div>
			</div>
			<div className="dashboard-grid">
				<div className="box">
					<h3>
						<Icon icon="faSitemap" />
						{i18n('appcommon.menutop.executionFlowsICreated')} ({executionFlowStoreMine.list.length})
					</h3>
					<div className="items">
						{executionFlowStoreMine.list.sort(sortByUrgency).map(renderExecutionFlowMenuItem)}
					</div>
				</div>
				<div className="box">
					<div className="title"></div>
					<h3>
						<Icon icon="faSitemap" />
						{i18n('appcommon.menutop.executionFlowsImResponsible')} ({executionFlowStore.list.length})
					</h3>
					<div className="items">
						{executionFlowStore.list.sort(sortByUrgency).map(renderExecutionFlowMenuItem)}
					</div>
				</div>
			</div>
		</content>
	);
};

export default connect(({ auth, executionFlow, myExecutionFlowStore, task, companyAreaStore, recurrenceStore }) => ({
	auth,
	executionFlowStore: executionFlow,
	executionFlowStoreMine: myExecutionFlowStore,
	taskStore: task,
	companyAreaStore,
	recurrenceStore,
}))(Dashboard);
