import React, { useState, useEffect } from 'react';
import firebase from '@firebase-config';
import { useSelector, connect } from 'react-redux';
import i18n, { format } from '@i18n';
import {
	Workflow,
	WorkflowActionType,
	WorkflowReactionType,
	WorkflowPhase as WorkflowPhaseShape,
	WorkflowPhaseAction as WorkflowPhaseActionShape,
	WorkflowPhaseReaction as WorkflowPhaseReactionShape,
	ProcessDocument as ProcessDocumentShape,
	ExecutedForm,
	ExecutionFlow,
	CompanyArea,
} from '@taugor/taugor-bpmn-models';
import Parser from 'react-html-parser';
import Message from '@custom/Message';
import { onMainChangeProp } from '@custom/common';
import { services, history, store } from '@redux';
import Icon from '@custom/Icon';
import { uid } from '@zerobytes/firebase-basic-service';
import Api from '@api';
import '@assets/sass/collections.scss';
import {
	Toolbar,
	Typography,
	TextField,
	Checkbox,
	Switch,
	FormControl,
	FormControlLabel,
	Button,
	IconButton,
	InputAdornment,
	Tooltip,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core';
import Input from '@custom/Input';
import { makeStyles } from '@material-ui/core/styles';
import Unauthorized from '../Unauthorized';

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
	},
}));

const oExecutedFormService = new ExecutedForm().getService(firebase, store, 'executedFormCollectionsStore');
const oWorkflowService = new Workflow().getService(firebase, store, 'workflowCollectionStore');
const oExecutionFlowService = new ExecutionFlow().getService(firebase);
const oCompanyAreaService = new CompanyArea().getService(firebase, store, 'allCompanyAreaStore');

let defaultForm = { action: { formFields: '' } };

const Form = ({ executedFormCollectionsStore, auth, allCompanyAreaStore }) => {
	const [state, setState] = useState({});
	const [currentCollection, updateCurrentCollection] = useState({ formTitle: '' });
	const [currentForm, setCurrentForm] = useState(defaultForm);
	const [workflows, setWorkflows] = useState([]);
	const [initialDate, setInitialDate] = useState();
	const [endDate, setEndDate] = useState();
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [filters, setFilters] = useState([]);
	const [currentAgent, setCurrentAgent] = useState('');

	const setCurrentCollection = (workflow) => {
		setCurrentAgent('');
		updateCurrentCollection(workflow);
	};

	const isCollectionsAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.collections.admin') > -1;
	const isCollectionsViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.collections.viewer') > -1;

	const getForms = (workflow, actionUid) => {
		let oInitialDate = initialDate;
		let oEndDate = endDate;
		if (oInitialDate && !(oInitialDate instanceof Date)) {
			oInitialDate = new Date(oInitialDate.substr(0, 4), oInitialDate.substr(5, 2), oInitialDate.substr(8, 2), 0, 0, 0, 0);
		}
		if (oEndDate && !(oEndDate instanceof Date)) {
			oEndDate = new Date(oEndDate.substr(0, 4), oEndDate.substr(5, 2), oEndDate.substr(8, 2), 23, 59, 59, 999);
		}
		let formFilters = [
			[
				['company', '==', auth.company.uid],
				['deleted', '==', false],
				['workflow', '==', workflow],
				['action.uid', '==', actionUid],
				['createdAt', '>=', oInitialDate],
				['createdAt', '<=', oEndDate],
			],
		];
		if (!auth.user.companyadmin) {
			// formFilters[0].push(['agent', '==', auth.user.uid]);
		}
		oExecutedFormService.filter(formFilters).limit(100).list();
	};

	useEffect(() => {
		updatePanelsHeight();
		oCompanyAreaService
			.filter([
				[
					['company', '==', auth.user.currentCompany.uid],
					['deleted', '==', false],
				],
			])
			// .order([['name', 'asc']])
			.list();

		let initial = new Date();
		initial.setDate(initial.getDate() - 30);
		let end = new Date();
		end.setDate(end.getDate() + 1);
		//(initial, end);
		setInitialDate(initial);
		setEndDate(end);
	}, []);

	useEffect(() => {
		getForms(currentCollection.workflow || '--none--', currentCollection.uid || '--none--');
		if (currentCollection.uid) {
			let filters = getFormFields(currentCollection);
			setFilters(filters);
		} else {
			setFilters([]);
		}
	}, [currentCollection]);

	useEffect(() => {
		if (currentForm.excutionFlowObject || currentForm.excutionFlowObject === false) return;
		//('currentForm.executionFlow', currentForm.executionFlow);
		oExecutionFlowService.get(currentForm.executionFlow || '--none--').then((executionFlow) => {
			//('executionFlow', executionFlow);
			currentForm.excutionFlowObject = executionFlow || false;
			setCurrentForm({ ...currentForm });
		});
	}, [currentForm]);

	if (!isCollectionsAdmin && !isCollectionsViewer) {
		return <Unauthorized />;
	}

	const getFormFields = (workflow) => {
		let fields = [];
		(workflow.formFields || '')
			.split(/(\;|\n)/g)
			.filter((s) => s.replace(/\;/g, '').trim())
			.map((fieldconfig, i) => {
				let field, type, s, options, mask;
				if (fieldconfig.indexOf('@') > -1) {
					field = fieldconfig.substr(0, fieldconfig.indexOf('@'));
					type = fieldconfig.substr(fieldconfig.indexOf('@') + 1, fieldconfig.length);
				} else {
					field = fieldconfig;
					type = 'string';
				}
				if (type.indexOf('(') > -1) {
					let optionsConfig = type.substr(type.indexOf('('), type.length).replace(')', '');
					type = type.substr(0, type.indexOf('('));
					options = optionsConfig.replace('(', '').split('\\');
				}
				if (type.indexOf('mask#') > -1) {
					s = type.split('mask#');
					type = '';
					mask = s[1];
				}
				if (type === 'cpf') {
					mask = '999.999.999-99';
					type = 'cpf';
				}
				if (type === 'cnpj') {
					mask = '99.999.999/9999-99';
					type = 'cnpj';
				}
				fields.push({ field, type, mask, options, value: type === 'checkbox' ? false : '' });
			});
		return fields;
	};

	const createList = (workflows) => {
		workflows.map((workflow) => {
			workflow.items = [];
			workflow.phases.map((phase) => {
				if (phase.deleted) return;
				phase.actions.map((action) => {
					if (action.deleted) return;
					if (action.type.type !== 'formInput') return;
					if (!action.formTitle) return;
					action.workflow = workflow.uid;
					workflow.items.push(action);
				});
			});
		});
		return workflows;
	};

	const renderFilters = () => {
		return filters.map((filter, i) => {
			let field = filter.field;
			let type = filter.type;
			let mask = filter.mask;
			let options = filter.options;
			if (filter.value === undefined) {
				filter.value = '';
			}
			if (type.indexOf('table') > -1) {
				return <div></div>;
			}
			if (type.indexOf('select') > -1) {
				return (
					<div>
						<TextField
							style={{ width: 120 }}
							label={field}
							value={filter.value}
							variant={'outlined'}
							onChange={onMainChangeProp(filters, setFilters, [i, 'value'])}
							select
						>
							<MenuItem value={''}>{i18n('allOptions')}</MenuItem>
							{options.map((option, a) => {
								return (
									<MenuItem key={a} value={option}>
										{option}
									</MenuItem>
								);
							})}
						</TextField>
					</div>
				);
			}
			if (type === 'checkbox') {
				return (
					<div key={i}>
						<FormControl style={{ width: '100%' }} variant="filled">
							<FormControlLabel
								label={field}
								className="checkbox-control"
								control={
									<Switch
										checked={filter.value}
										onChange={onMainChangeProp(filters, setFilters, [i, 'value'], true)}
									/>
								}
							/>
						</FormControl>
					</div>
				);
			}

			return (
				<div key={i}>
					<Input
						mode="top-label outlined "
						label={field}
						type={type}
						mask={mask}
						alwaysShowMask={!!mask}
						// multiline={type === 'textarea'}
						value={filter.value}
						onChange={onMainChangeProp(filters, setFilters, [i, 'value'])}
					/>
				</div>
			);
		});
	};
	let currentAgents = {};
	const filteredList = executedFormCollectionsStore.list.filter((a) => {
		currentAgents[a.action.agent] = {
			name: a.action.agentName,
			email: a.action.agentEmail,
			uid: a.action.agent,
		};
		if (currentAgent && a.action.agent !== currentAgent) return false;
		let passed = true;
		filters.find((filter) => {
			if (filter.value === '' || filter.value === null) return;
			//(a, filter, a.fields[filter.field]);
			if (!!a.fields[filter.field] && filter.type !== 'checkbox') {
				if (a.fields[filter.field].indexOf(filter.value) == -1) {
					passed = false;
					return filter;
				}
			// } else {
			// 	passed = false;
			// 	return filter;
			}
		});
		return passed;
	});
	const toggleCompanyArea = (ca) => (e) => {
		ca.opened = !ca.opened;
		if (ca.opened) {
			ca.loading = true;
			oWorkflowService
				.filter([
					[
						['company', '==', auth.user.currentCompany.uid],
						['deleted', '==', false],
						['division', '==', ca.uid],
					],
				])
				.list()
				.then((list) => {
					//('list', list);
					ca.loading = false;
					ca.workflows = createList(list);
					setState({});
				});
		}
		setState({});
	};

	return (
		<content id="collections">
			<header id="page-header">
				<div>
					<h2>
						<Icon icon="faFileAlt" style={{ marginRight: 10 }} />
						{i18n('workflow.collections.title')}
					</h2>
					<div>{i18n('workflow.collections.subtitle')}</div>
				</div>
			</header>
			<div className="columns" id="columns">
				<div className="file-column">
					<div className="title toolbar green">
						<div className="text">{i18n('workflow.collections.formList')}</div>
					</div>
					<div className="list-items">
						{allCompanyAreaStore.list
							.sort((a, b) => {
								if (a.name > b.name) return 1;
								if (a.name < b.name) return -1;
								return 0;
							})
							.map((ca, cak) => {
								return (
									<div className={`company-area ${ca.opened ? 'opened' : ''}`} key={cak}>
										<div className="company-area-name" onClick={toggleCompanyArea(ca)}>
											<Icon icon={ca.opened ? 'faChevronDown' : 'faChevronRight'} />
											{ca.name} {ca.delete ? ' - ' + i18n('deleted') : ''}
										</div>
										<div className="content">
											{ca.workflows
												.sort((a, b) => {
													if (a.document.title > b.document.title) return 1;
													if (a.document.title < b.document.title) return -1;
													return 0;
												})
												.map((workflow, i) => {
													if (!workflow.document.title || !workflow.items || !workflow.items.length) return;
													return (
														<div key={i}>
															<div className="workflow-title">{workflow.document.title}</div>
															{workflow.items.map((action, i) => {
																return (
																	<div
																		key={i}
																		className={`item ${action.workflow === workflow.uid &&
																			currentCollection.uid === action.uid
																			? 'current'
																			: ''
																			}`}
																		onClick={(e) => {
																			setCurrentCollection(action);
																			setCurrentForm(defaultForm);
																		}}
																	>
																		{action.formTitle}
																	</div>
																);
															})}
														</div>
													);
												})}
										</div>
									</div>
								);
							})}
					</div>
				</div>
				<div className="file-column">
					<div className="title toolbar yellow">
						<div className="text">
							{currentCollection.formTitle || i18n('workflow.collections.selectAForm')}
							{currentCollection.uid ? ` (${filteredList.length}/${executedFormCollectionsStore.list.length})` : ''}
						</div>
						<div className="spacer"></div>
						{!!currentCollection.uid && (
							<Tooltip title={i18n('generate.document')}>
								<simple-button
									onClick={async (e) => {
										try {
											const a = await Api.cvs.cvsWrite(filteredList);
											var win = window.open(a.data, '_blank');
											win.focus();
										} catch (err) {
											console.log(err);
										}
									}}
								>
									<Icon icon="faFileDownload" />
								</simple-button>
							</Tooltip>
						)}
						<Tooltip title={i18n('filter')}>
							<simple-button
								iconed
								onClick={(e) => {
									setFiltersVisible(!filtersVisible);
								}}
							>
								<Icon icon="faFilter" />
							</simple-button>
						</Tooltip>
					</div>
					{!!currentCollection.uid && filtersVisible && (
						<div>
							<div className="toolbar  yellow down">
								<TextField
									variant="outlined"
									type={'date'}
									value={initialDate instanceof Date ? initialDate.toISOString().substr(0, 10) : initialDate}
									onChange={(e) => {
										setInitialDate(e.target.value);
									}}
								/>
								<TextField
									variant="outlined"
									type={'date'}
									value={endDate instanceof Date ? endDate.toISOString().substr(0, 10) : endDate}
									onChange={(e) => {
										setEndDate(e.target.value);
									}}
								/>
								<simple-button
									iconed
									onClick={(e) => getForms(currentCollection.workflow, currentCollection.uid)}
								>
									<Icon icon="faSync" />
								</simple-button>
							</div>
							<div className="toolbar yellow down">
								<TextField
									label={i18n('executionFlow.form.activity.agent')}
									onChange={(e) => setCurrentAgent(e.target.value)}
									select
								>
									<MenuItem value="">{i18n('allOptions')}</MenuItem>
									{Object.values(currentAgents).map((agent) => {
										return <MenuItem value={agent.uid}>{agent.name}</MenuItem>;
									})}
								</TextField>
							</div>
							<div className="toolbar yellow down wrap">{renderFilters()}</div>
						</div>
					)}
					<div className="list-items" style={{minHeight:'40%'}}>
						{!!executedFormCollectionsStore.list.length && (
							<div>
								{filteredList
									.sort((a, b) => {
										if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
										if (a.createdAt.toDate() > b.createdAt.toDate()) return -1;
										return 0;
									})
									.map((item, i) => {
										return (
											<div
												key={i}
												className={`item ${currentForm.uid === item.uid ? 'current' : ''}`}
												onClick={(e) => setCurrentForm(item)}
											>
												<div>{item.action.agentName}</div>
												<div>{item.action.agentEmail}</div>
												<div className={'date'}>{format.datetime(item.createdAt.toDate())}</div>
											</div>
										);
									})}
							</div>
						)}
					</div>
				</div>
				<div className="file-column">
					<div className="title toolbar teal">
						<div className="text">{i18n('workflow.collections.formFill')}</div>
					</div>
					{!!currentForm.uid && (
						<div>
							{!!currentForm.excutionFlowObject && (
								<div className="toolbar teal down">
									<div className="item display">
										<div>{currentForm.excutionFlowObject.workflowObject.description}</div>
									</div>
									<simple-button
										onClick={(e) => {
											window.open(`/executionFlow/view/${currentForm.executionFlow}`, 'blank');
										}}
									>
										{i18n('toAccess')}
									</simple-button>
								</div>
							)}
							<div className="toolbar teal down">
								<div className="item display">
									<div className="field-name">Data de Criação</div>
									<div className="field-value">{format.datetime(currentForm.createdAt.toDate())}</div>
								</div>
								<div className="item display">
									<div className="field-name">Criador por</div>
									<div className="field-value">{currentForm.action.agentName}</div>
									<div className="field-value">{currentForm.action.group.name}</div>
								</div>
							</div>
						</div>
					)}
					<div className="list-items">
						{getFormFields(currentForm.action).map((fieldConfig, i) => {
							if (currentForm.fields[fieldConfig.field] === undefined) {
								currentForm.fields[fieldConfig.field] = '';
							}

							let fieldValue = currentForm.fields[fieldConfig.field];
							if (fieldConfig.type === 'checkbox') {
								if (fieldValue === true) {
									fieldValue = <Icon icon="faCheck" />;
								} else {
									fieldValue = <Icon icon="faTimes" />;
								}
							}
							if (fieldConfig.type == 'table') {
								fieldValue = (
									<table className="table-sheet-view">
										<tr className="row headers">
											{fieldConfig.options.map((col, a) => {
												return (
													<th key={a} className="col">
														{col}
													</th>
												);
											})}
										</tr>

										{fieldValue.map((values, i) => {
											return (
												<tr className="row" key={i}>
													{fieldConfig.options.map((col, a) => {
														return (
															<td key={a} className="col">
																{Parser(values[col])}
															</td>
														);
													})}
												</tr>
											);
										})}
									</table>
								);
							}
							if (fieldConfig.type === 'color') {
								fieldValue = (
									<div
										style={{
											width: 30,
											height: 30,
											background: fieldValue,
										}}
									></div>
								);
							}

							return (
								<div key={i} className="item display">
									<div className="field-name">{fieldConfig.field}</div>
									<div className="field-value">{fieldValue || 'Não Preenchido'}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</content>
	);
};

const updatePanelsHeight = () => {
	let appBar = document.getElementById('ultra-bar');
	let header = document.getElementById('page-header') || { offsetHeight: 0 };
	let columns = document.getElementById('columns');

	if (!columns) return;
	let maxHeight = document.body.offsetHeight - appBar.offsetHeight - header.offsetHeight;
	columns.style.maxHeight = maxHeight + 'px';
};
window.addEventListener(
	'resize',
	() => {
		updatePanelsHeight();
	},
	false
);

const groupByItemProperty = (array, properties, titleProp, reduceItem) => {
	properties = properties instanceof Array ? properties : [properties];
	reduceItem = typeof reduceItem === 'function' ? reduceItem : (o) => o;
	const result = {};

	(array || [])
		.sort((a, b) => {
			if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
			if (a.createdAt.toDate() > b.createdAt.toDate()) return -1;
			return 0;
		})
		.map((item) => {
			let oItem = item;
			let prop = properties.slice(-1);
			properties.slice(0, -1).map((property) => {
				oItem = item[property];
			});

			if (!result[oItem[prop]]) {
				result[oItem[prop]] = {
					title: oItem[titleProp],
					id: oItem[prop],
					list: [],
				};
			}
			if (oItem[prop] === undefined) {
				return;
			}
			result[oItem[prop]].list.push(reduceItem(item));
		});
	return result;
};

export default connect(({ auth, executedFormCollectionsStore, companyAreaStore, allCompanyAreaStore }) => ({
	auth,
	executedFormCollectionsStore,
	companyAreaStore,
	allCompanyAreaStore,
}))(Form);