import React, { useState, useEffect } from 'react';
import firebase, { workfluxFirestore } from '@firebase-config';
import { connect } from 'react-redux';
import { appcenter, store as zerocodeStore, firebaseNewton, dokia } from 'zerocode';
import i18n from '@i18n';
// import Api from '@api';
import {
	CompanyArea,
	Workflow,
	ExecutedApproval,
	ExecutedDocument,
	ExecutedForm,
	Recurrence,
	// WorkflowActionType,
	// WorkflowReactionType,
} from '@taugor/taugor-bpmn-models';
// import { WorkflowPhase } from '@taugor/taugor-bpmn-models';
import Icon from '@custom/Icon';
import Input from '@custom/Input';
// import { generateUid } from '@custom/common';
// import { uid } from '@zerobytes/firebase-basic-service';
// import Message from '@custom/Message';
import { store, history, services } from '@redux';
import {
	Button,
	TextField,
	// List,
	// ListItem,
	// ListItemText,
	// ListItemIcon,
	// IconButton,
	// FormControl,
	// Select,
	// MenuItem,
	// InputLabel,
	// Fab,
	// Typography,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Unauthorized from '../Unauthorized';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import clsx from 'clsx';
import '@assets/sass/area.scss';
// import WorkflowStartup from '../workflow/WorkflowStartup';
import WorkflowActionCard, { RecurrenceWindow, removeWorkflow, checkLicenses } from '../../@custom/WorkflowActionCard';
import { workfluxStorage } from '../../@firebase-config';
import { ModalForm } from './modules/modal';

const oCompanyArea = new CompanyArea();
const oWorkflow = new Workflow();
const oRecurrence = new Recurrence();
const oExecutedApproval = new ExecutedApproval();
const oExecutedDocument = new ExecutedDocument();
const oExecutedForm = new ExecutedForm();

const oCompanyAreaService = oCompanyArea.getService(firebase);
const oWorkflowService = oWorkflow.getService(firebase, store);

const firestore = workfluxFirestore;
const workflowCollection = firestore.collection(oWorkflow.getModelName());
const recurrenceCollection = firestore.collection(oRecurrence.getModelName());
const executedApprovalCollection = firestore.collection(oExecutedApproval.getModelName());
const executedDocumentCollection = firestore.collection(oExecutedDocument.getModelName());
const executedFormCollection = firestore.collection(oExecutedForm.getModelName());

const managementCollection = firestore.collection("managementProtocol");

// workflux.collections.admin
// workflux.collections.viewer
// workflux.companyarea.admin
// workflux.companyarea.viewer
// workflux.executions.admin
// workflux.executions.viewer
// workflux.mapping.admin
// workflux.mapping.viewer

const Form = ({ companyAreaStore, auth, recurrenceStore, companyStore }) => {
	oCompanyArea.company = auth.company.uid;
	const [companyArea, setCompanyArea] = useState(oCompanyArea);

	const [prefix, setPrefix] = useState("");
	const [renderModal, setRenderModal] = useState(false);
	const [security, setSecurity] = useState(companyArea?.security === "public" || false);
	const [errorPrefix, setErrorPrefix] = useState(false);
	const [errors, setErrors] = useState({});
	const [workflows, setWorkflows] = useState([]);
	// const [currentWorkflow, setCurrentWorkflow] = useState([]);
	// const [recurrence, setRecurrence] = useState({
	// 	company: '',
	// 	workflow: '',
	// 	workflowObject: '',
	// 	executionHistory: '',
	// 	rule: '',
	// 	daily: '',
	// 	hourly: '',
	// 	weekly: '',
	// 	monthly: '',
	// 	everyXHours: '',
	// 	everySpecificDay: '',
	// 	everySpecificMonth: '',
	// });
	// const [showRecurrenceWindow, setShowRecurrenceWindow] = useState(false);
	const [mobileFormVisible, setMobileFormVisible] = useState(false);
	// const [showCopyWindow, setShowCopyWindow] = useState(false);

	const isCompanyAreaAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.companyarea.admin') > -1;
	const isCompanyAreaViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.companyarea.viewer') > -1;
	const isMappingAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.admin') > -1;
	const isMappingViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.viewer') > -1;

	const [hasLicensesMappings, setHasLicensesMappings] = useState(false);
	const [loadingHasLicenses, setLoadingHasLicenses] = useState(false);
	const [maxMappingsLicensesAvailable, setMaxMappingsLicensesAvailable] = useState(false);

	const appcenterStore = zerocodeStore.getState().appcenter;

	useEffect(() => {
		checkNecessaryLicenses();
	}, [appcenterStore.licenses]);

	const checkNecessaryLicenses = () => {
		checkLicenses((result) => {
			setHasLicensesMappings(result.hasEnoughMappingsLicense);
			setMaxMappingsLicensesAvailable(result.mappingsLicensesAvailable);

			setLoadingHasLicenses(true);
		});
	};

	useEffect(() => {
		if (!auth.isAuthenticated) return;
		oWorkflowService
			.filter([
				[
					['division', '==', companyAreaStore.current.uid || '--none--'],
					['company', '==', auth.company.uid],
					['deleted', '==', false],
				],
			])
			.list(false, false)
			.then((r) => {
				setWorkflows(r);
			})
			.finally(services.spinner.hide);
	}, [auth.company.uid, auth.isAuthenticated, companyAreaStore, companyAreaStore.current.uid]);

	if (!isCompanyAreaViewer && !isCompanyAreaAdmin) {
		return <Unauthorized />;
	}

	const loadCompanyArea = (companyArea) => {
		if (!companyArea.groups) {
			companyArea.groups = [];
		}
		setCompanyArea({ ...companyArea });
		setSecurity(companyArea?.security === "public")
	};

	const onChangeProp = (prop) => (e) => {
		loadCompanyArea({
			groups: [],
			...companyArea,
			[prop]: e.target.value,
		});

		oCompanyArea[prop] = e.target.value;
		let valid = oCompanyArea.$fieldConfig[prop].validate();
		if (valid.length) {
			errors[prop] = valid;
		} else {
			delete errors[prop];
		}
		setErrors(errors);
	};


	const showError = (prop) => {
		return errors[prop] ? i18n(`form.errors.${errors[prop][0]}`) : <span></span>;
	};

	const save = async () => {

		oCompanyArea.$fill(companyArea);
		oCompanyArea.uid = companyArea.uid;
		oCompanyArea.company = auth.company.uid;
		oCompanyArea.security = security ? "public" : "private"

		let err = oCompanyArea.$validate();
		setErrors(err);


		if (Object.keys(err).length) {
			if (prefix === "") {
				setErrorPrefix(true);
			}
			return;
		}
		if (prefix === "") {
			setErrorPrefix(true);
		}


		await oCompanyAreaService.save(oCompanyArea).then((r) => {
			loadCompanyArea(r);
			companyAreaStore.current = r;
			managementCollection.doc(r.uid).set({
				prefix,
				uid: r.uid,
				date: new Date()
			})
		});
	};
	const edit = (ca) => async (e) => {
		setPrefix("")
		setRenderModal(false)

		const response = await managementCollection.doc(ca.uid).get();

		const getPrefix = response.data()?.prefix
		if (getPrefix) {
			setPrefix(getPrefix)
		}

		setMobileFormVisible(true);

		companyAreaStore.current = ca;
		e.stopPropagation();
		setErrors({});

		loadCompanyArea(ca);
	};
	const clearForm = () => {
		setMobileFormVisible(true);
		loadCompanyArea(new CompanyArea());
	};

	const renderWorkflows = () => {
		if (!isMappingViewer && !isMappingAdmin) return;
		return (
			<div>
				{isMappingAdmin && (
					<div>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Icon icon="faPlus" size="lg" />}
							onClick={() => {
								companyAreaStore.current = companyArea;
								history.push('/workflow/form');
							}}
							disabled={!hasLicensesMappings}
						>
							Workflow
						</Button>
					</div>
				)}
				{!hasLicensesMappings && loadingHasLicenses && (
					<div className="message-danger">{i18n('without-licences-mapping')}</div>
				)}
				<div className="workflow-list">
					{workflows
						.sort((a, b) => {
							if (a.document.title > b.document.title) return 1;
							if (a.document.title < b.document.title) return -1;
							return 0;
						})
						.map((workflow, i) => {
							return (
								<WorkflowActionCard
									key={workflow.uid}
									workflow={workflow}
									hasLicensesMappings={hasLicensesMappings}
									maxMappingsLicensesAvailable={maxMappingsLicensesAvailable}
								/>
							);
						})}
				</div>
			</div>
		);
	};

	return (
		<content className="area">
			<header id="page-header">
				<h3>
					<Icon icon="faFolderOpen" />

				</h3>
				<div style={{ flex: 1 }}></div>
				<div className="header-buttons ">
					{companyArea && companyArea.uid && isCompanyAreaAdmin && (
						<div
							className="header-button delete "
							onClick={(e) => {
								services.message.show(
									i18n('companyArea.delete.window.title'),
									<div>
										<div>{i18n('action.cant.be.undone')}</div>
										<div>{i18n('companyArea.delete.message.explanation')}</div>
										<div className="buttons">
											<simple-button
												clear
												onClick={(e) => {
													services.spinner.show();
													let companyAreaId = companyArea.uid;
													oCompanyAreaService
														.patch(companyArea.uid, {
															deleted: true,
															deletedBy: auth.user,
														})
														.then((r) => {
															workflows.map((w) => {
																if (w.division === companyAreaId) {
																	removeWorkflow(auth, checkNecessaryLicenses, w, false)();
																}
															});
															services.message.hide();
															services.spinner.hide();
														});
												}}
											>
												{i18n('delete')}
											</simple-button>
											<simple-button cancel onClick={services.message.hide}>
												{i18n('cancel')}
											</simple-button>
										</div>
									</div>
								);
							}}
						>
							<Icon icon="faTrash" size={'2x'} />
						</div>
					)}
					{isCompanyAreaAdmin && (
						<div className="header-button add" onClick={clearForm}>
							<Icon icon="faPlus" size={'2x'} />
						</div>
					)}

					{isCompanyAreaAdmin && (
						<div className={'header-button hidden-on-mobile save'} onClick={save}>
							<Icon icon="faSave" size="lg" />
						</div>
					)}
				</div>
			</header>
			<div className="entity-management">
				<div id="list-panel">
					<div className="area-list">
						{companyAreaStore.list
							.sort((a, b) => {
								if (a.name > b.name) return 1;
								if (a.name < b.name) return -1;
								return 0;
							})
							.filter((ca) => {
								if (auth.user.companyadmin) return true;
								if (ca.groups && ca.groups.length) {
									let found = false;
									ca.groups.find((group) => {
										auth.user.groups.find((uGroup) => {
											if (uGroup.uid === group.uid) {
												found = true;
												return uGroup;
											}
										});
										if (found) {
											return found;
										}
									});
									return found;
								}
								return true;
							})
							.map((item, i) => {
								return (
									<div
										className={`area-list-item
											with-columns 
											${item.uid === companyArea.uid ? 'selected' : ''}
										`}
										key={i}
										onClick={edit(item)}
									>
										{!item.$selected && (
											<div className={'left-icon'}>
												<Icon icon={item.uid === companyArea.uid ? 'faFolderOpen' : 'faFolder'} />
											</div>
										)}

										{item.$selected && (
											<div className={'left-icon'}>
												<Icon icon="faCheck" />
											</div>
										)}

										<div className="area-content" style={{ flex: 1 }}>
											<div className="line area-name">{item.name}</div>
											<div className="line area-description">{item.description}</div>
										</div>
										<div className="area-actions">
											{isMappingAdmin && (
												<div
													className={'add-workflow' + (!hasLicensesMappings && ' disabled')}
													onClick={(e) => {
														companyAreaStore.current = item;
														history.push('/workflow/form');
													}}
												>
													<Icon icon="faPlus" />
													Workflow
												</div>
											)}
										</div>

										<div className="right-icon">
											<Icon icon="faChevronRight" />
										</div>
									</div>
								);
							})}
					</div>
				</div>
				<div
					id="form-panel"
					className={`${mobileFormVisible ? 'visible' : ''}
					${companyArea.uid ? 'editing-area' : ''}
				`}
				>
					<div className="form-panel-header" id="form-panel-header">
						<h5>{i18n('companyArea.form.screenTitle')}</h5>

						<div className="header-button" onClick={save}>
							<Icon icon="faSave" />
						</div>
						<div
							className="header-button"
							onClick={() => {
								setMobileFormVisible(false);
								loadCompanyArea(new CompanyArea());
							}}
						>
							<Icon icon="faTimes" />
						</div>
					</div>
					<div
						style={{
							fontSize: 12,
							fontWeight: 'bold',
							color: '#666',
							marginBottom: 10,
						}}
					>
						{companyArea.uid ? `${i18n('companyArea.form.editingId')} ${companyArea.uid}` : ''}
					</div>
					<Input
						leftIcon="faFolder"
						mode="label-from-left"
						label={i18n('companyArea.form.item.name')}
						value={companyArea.name}
						onChange={onChangeProp('name')}
						message={showError('name')}
						disabled={!isCompanyAreaAdmin}
					/>
					<Input
						leftIcon="faInfo"
						mode="label-from-left"
						label={i18n('companyArea.form.item.description')}
						value={companyArea.description}
						onChange={onChangeProp('description')}
						message={showError('description')}
						disabled={!isCompanyAreaAdmin}
						multiline={true}
					/>

					{/* <div>
						<FormControlLabel
							control={
								<Checkbox
									checked={security}
									onChange={() => setSecurity(!security)}
								/>
							}
							label={i18n("companyArea.form.item.security")}

						/>
					</div> */}

					<section style={{
						position: "relative"
					}} >
						<Input
							leftIcon="faFolder"
							mode="label-from-left"
							label={i18n('companyArea.form.item.managementProtocol')}
							value={prefix}
							// onChange={(e) => handleChangePrefix(e.target.value)}
							message={errorPrefix && i18n("form.erros.fillInThisField")}
							disabled={true}
						/>
						<section onClick={() => {
							if (prefix === "") {
								setRenderModal(true)
							}
						}
						}

							style={{
								position: "absolute",
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								zIndex: 800
							}}>

						</section>
					</section>
					{
						renderModal &&
						<ModalForm
							setPrefix={setPrefix}
							setRenderModal={setRenderModal}
							prefix={prefix}
						/>
					}

					<Autocomplete
						value={companyArea.groups}
						onChange={(event, newValue) => {
							companyArea.groups = newValue;
							loadCompanyArea(companyArea);
						}}
						multiple
						id="tags-standard"
						options={appcenterStore.groups}
						getOptionLabel={(option) => option.name || ''}
						defaultValue={[]}
						disabled={!isCompanyAreaAdmin}
						renderInput={(params) => <TextField {...params} variant="standard" label={i18n("groups")} placeholder={i18n("groups")} />}
					/>
					<div style={{ marginBottom: 20 }}></div>

					{!!companyArea.uid && renderWorkflows()}

				</div>
			</div>
		</content>
	);
};

export default connect(({ auth, companyAreaStore, recurrenceStore, company }) => ({
	auth,
	companyAreaStore,
	recurrenceStore,
	companyStore: company,
}))(Form);
