import React, { useState, useEffect } from 'react';
import i18n from '@i18n';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import { services, history } from '@redux';
import Unauthorized from './Unauthorized';

const SSO = ({ match }) => {
	const [error, setError] = useState('');

	useEffect(() => {
		if (!match.params.token) return;

		services.spinner.show();
		services.auth
			.sso(match.params.token)
			.then((r) => {
				history.push('/');
			})
			.catch((e) => {
				setError(true);
			});
	}, []);
	if (!match.params.token) {
		return <Unauthorized />;
	}

	return error ? (
		<Unauthorized />
	) : (
		<content>
			<header>
				<div style={{ padding: 50, fontSize: 18 }}></div>
			</header>
		</content>
	);
};

export default SSO;
