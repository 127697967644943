import React, { useRef, useState } from 'react';

import './modal.css';

import i18n from "@i18n";
import Input from '@custom/Input';
import { TextField, MenuItem } from '@material-ui/core';

export function ModalForm({ setRenderModal, setPrefix, prefix }) {

  const [dataPrefix, setDataPrefix] = useState({
    sector: "",
    errorSector: false,
    date: "",
    errorDate: false,
    digits: "",
    errorDigits: false,
  })
  const listValueDate = [
    {
      id: 1,
      name: "[AAAA]"
    },
    {
      id: 2,
      name: "[AA]"
    },
    {
      id: 3,
      name: "[HH]"
    },
    {
      id: 4,
      name: "[MM]"
    },
    {
      id: 5,
      name: "[DD]"
    },
    {
      id: 8,
      name: "[DAY]"
    },
  ]
  const containerRef = useRef();

  const handleChange = ({ prop, value }) => {

    if (prop === "sector") {
      const sectorFormated = value.replace(/[^a-zA-Z]/g, '')
      value = sectorFormated.toUpperCase()
    }

    if (prop === "digits") {

      if (value > 10) {
        return
      }
    }
    setDataPrefix({ ...dataPrefix, [prop]: value })
  }

  const save = () => {

    let errorDate = false
    let errorSector = false
    let errorDigits = false

    if (dataPrefix.date === "") {
      errorDate = true
    }
    if (dataPrefix.digits.length === 0) {
      errorDigits = true
    }
    if (dataPrefix.sector === "") {
      errorSector = true
    }

    if (errorDate || errorSector || errorDigits) {

      setDataPrefix({
        ...dataPrefix,
        errorSector: errorSector,
        errorDate: errorDate,
        errorDigits: errorDigits
      });


      return
    }

    const formatedPrefix = `${dataPrefix.sector}-${dataPrefix.date}-${"0".repeat(dataPrefix.digits)}`
    setPrefix(formatedPrefix);
    setRenderModal(false)
  }

  const handleClose = (e) => {
    if (e.target === containerRef.current) {
      setRenderModal(false)
    }
  }

  return (
    <section
      id='modalFormManagementProtocol'
      ref={containerRef}
      onClick={(e) => handleClose(e)}
    >
      <section
        className='contentModalForm'
      >
        <section
          className='headerModalForm'
        >
          <h1>{i18n('companyArea.form.item.managementProtocol')}</h1>
          <button
            onClick={() => setRenderModal(false)}
          >
            X
          </button>
        </section>
        <section >
          <Input
            leftIcon="faFolder"
            mode="label-from-left"
            label={"setor"}
            value={dataPrefix.sector}
            onChange={(e) => handleChange({ prop: "sector", value: e.target.value })}
            message={dataPrefix.errorSector && i18n("informTheSector")}
          />
          <TextField
            variant="filled"
            style={{ width: "100%", marginBottom: 10 }}
            label={i18n("date")}
            value={dataPrefix.date}
            onChange={(e) => handleChange({ prop: "date", value: e.target.value })}
            InputLabelProps={{ shrink: true }}
            select
            required
          >
            <MenuItem value={''}>{"Date"}</MenuItem>
            {listValueDate.map((cod) => {
              return (
                <MenuItem key={cod.id} value={cod.name}>
                  {cod.name}
                </MenuItem>
              );
            })}
          </TextField>

          <Input
            leftIcon="faFolder"
            mode="label-from-left"
            label={i18n("digits")}
            type={"number"}
            min="1"
            max="10"
            value={dataPrefix.digits}
            onChange={(e) => handleChange({ prop: "digits", value: e.target.value })}
            message={dataPrefix.errorDigits && i18n("enterDigitNumber")}
          />
        </section>
        <section id='areaButtonConfirmModalForm'>
          <button onClick={save}>{i18n("save")}</button>
        </section>

      </section>
    </section>
  )
}